/*
 * NOTE:
 * 1. 新增/改变路由，需要在增加/检查权限配置
 */
import { OPERATION_PATH } from "@aspen/libs";

interface IRoleItem {
  exact: boolean;
  showHeader?: boolean; // 是否展示顶部导航, 临时方案，新闻详情不需要登陆可访问，但是也需要展示顶部导航菜单，其他事是needAuthenticated判断的，后续考虑优化 next todo
  needAuthenticated?: boolean; // 是否需要登陆权限, 默认false
  role?: string[]; // 可以访问的角色列表, 不设置或者为空，即不限角色， 只有在needAuthenticate为true的情况下才需要配置role
  titleKey?: string; // 路由的title
  titleDescription?: string; // 路由的title description
}
export type IRole = {
  [propName: string]: IRoleItem;
};

/*
 * desc： 路由/角色/配置
 * 不配置路由权限或者为空，即不限角色role
 * 只有在needAuthenticate为true的情况下才需要配置role
 *
 * 根据域名判断，登陆路由模块是否存在 （域名中存在partner时为代理商CMS登陆, 存在service时为SAS登陆 仅显示登陆login路由 本地调试代理商的话需要partner改为local）
 */
// operation路由不需要登录
const loginRouteConfig: IRole = {
  [OPERATION_PATH.CLIENT]: {
    exact: true,
    role: [],
    needAuthenticated: false,
    titleKey: "router.title.clients.partner.management",
    titleDescription: ""
  },
  [OPERATION_PATH.CUSTOMER_DETAIL]: {
    exact: true,
    role: [],
    needAuthenticated: false,
    titleKey: "router.title.clients.partner.customer.detail",
    titleDescription: ""
  }
};

export const routeConfig = loginRouteConfig;
